export const IVA_PCT = 19
export const SiiDocumentTypes = Object.freeze({
	PURCHASE_INVOICE_CODE: '46',
	SALE_INVOICE_CODE: '33',
	CREDIT_NOTE_CODE: '61',
	SALE_RECEIPT_CODE: '39',
})
export const DocumentStatus = Object.freeze({
	DRAFT: '1',
	PENDING: '6',
	PROCESSING: '2',
	ISSUED: '3',
	FAILED: '4',
	DELETED: '5',
	INVALID: '7',
})

