import useAuthStore from '@/stores/auth-store'
import useCompaniesStore from '@/stores/companies-store'
import usePermissionsStore, { Permissions } from '@/stores/permissions-store'
import { lazy } from 'react'
import {
	Activity,
	Box,
	Briefcase,
	Calendar,
	CreditCard,
	FileText,
	List,
	Plus,
	Settings,
	Share2,
	Star,
	Table,
	User,
	X,
} from 'react-feather'

const shouldBeLoggedIn = () => {
	const isLoggedIn = useAuthStore.getState().isLoggedIn()
	if (!isLoggedIn) {
		window.sessionStorage.setItem('login_redirect', window.location.pathname)
		return `/login`
	}
}

const shouldHaveActiveCompany = () => {
	const activeCompany = useCompaniesStore.getState().activeCompany
	if (!activeCompany) return '/companies'
}

const userShould = (permission) => () => usePermissionsStore.getState().userShould(permission)

const shouldSaleInvoiceBeNotDisabled = () => {
	const activeCompany = useCompaniesStore.getState().activeCompany
	if (activeCompany?.issuer_sale_status == 'disabled') return '/companies'
}

const shouldSaleReceiptBeNotDisabled = () => {
	const activeCompany = useCompaniesStore.getState().activeCompany
	if (activeCompany?.issuer_sale_receipt_status == 'disabled') return '/companies'
}

const shouldPurchaseInvoiceBeNotDisabled = () => {
	const activeCompany = useCompaniesStore.getState().activeCompany
	if (activeCompany?.issuer_purchase_status == 'disabled') return '/companies'
}

export default [
	{
		path: '/dashboard',
		component: lazy(() => import('@/views/DashboardView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany],
		title: 'Inicio',
		icon: Activity,
	},

	// purchase invoice --------------------------
	{
		path: '/documents',
		component: lazy(() => import('@/views/Documents/DocumentsView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany],
		title: 'Documentos',
		icon: FileText,
	},
	{
		path: '/purchase-invoice/issue',
		component: lazy(() => import('@/views/Documents/PurchaseInvoiceIssueView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldPurchaseInvoiceBeNotDisabled,
			userShould(Permissions.ISSUE_DOCUMENTS),
		],
		title: 'Emitir Facturas de Compra',
		icon: Plus,
	},
	{
		path: '/purchase-invoice/bulk',
		component: lazy(() => import('@/views/Documents/PurchaseInvoiceIssueBulkView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldPurchaseInvoiceBeNotDisabled,
			userShould(Permissions.ISSUE_DOCUMENTS),
		],
		title: 'Emitir Facturas de Compra desde Archivo',
		icon: Table,
	},
	{
		path: '/purchase-invoice/programmed',
		component: lazy(() => import('@/views/Documents/PurchaseInvoiceProgrammedView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldPurchaseInvoiceBeNotDisabled,
			userShould(Permissions.EDIT_PROGRAMMED_DOCUMENTS),
		],
		title: 'Programar Facturas de Compra',
		icon: Calendar,
	},
	{
		path: '/suppliers-integrations',
		component: lazy(() => import('@/views/SuppliersIntegrationsView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldPurchaseInvoiceBeNotDisabled,
			userShould(Permissions.EDIT_INTEGRATIONS),
		],
		title: 'Integraciones de Servicios Digitales',
		icon: Share2,
	},

	{
		path: '/sale-invoice/issue',
		component: lazy(() => import('@/views/Documents/SaleInvoiceIssueView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldSaleInvoiceBeNotDisabled,
			userShould(Permissions.ISSUE_DOCUMENTS),
		],
		title: 'Emitir Facturas de Venta',
		icon: Plus,
	},
	{
		path: '/sale-invoice/programmed',
		component: lazy(() => import('@/views/Documents/SaleInvoiceProgrammedView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldSaleInvoiceBeNotDisabled,
			userShould(Permissions.EDIT_PROGRAMMED_DOCUMENTS),
		],
		title: 'Programar Facturas de Venta',
		icon: Calendar,
	},

	{
		path: '/sale-receipt/issue',
		component: lazy(() => import('@/views/Documents/SaleReceiptIssueView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldSaleReceiptBeNotDisabled,
			userShould(Permissions.ISSUE_DOCUMENTS),
		],
		title: 'Emitir Boletas',
		icon: Plus,
	},

	{
		path: '/sales-integrations',
		component: lazy(() => import('@/views/SalesIntegrationsView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldSaleReceiptBeNotDisabled,
			userShould(Permissions.EDIT_INTEGRATIONS),
		],
		title: 'Integraciones E-commerce',
		icon: Share2,
	},

	// general routes --------------------------------------
	{
		path: '/suppliers',
		component: lazy(() => import('@/views/SuppliersView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany],
		title: 'Listado de Proveedores',
		icon: List,
	},
	{
		path: '/clients',
		component: lazy(() => import('@/views/ClientsView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany],
		title: 'Listado de Clientes',
		icon: List,
	},
	{
		path: '/account',
		component: lazy(() => import('@/views/account-settings')),
		beforeRoute: [shouldBeLoggedIn],
		title: 'Usuario',
		icon: User,
	},
	{
		path: '/companies',
		component: lazy(() => import('@/views/CompaniesView')),
		beforeRoute: [shouldBeLoggedIn],
		title: 'Empresa',
		icon: Briefcase,
	},
	{
		path: '/referral-program',
		component: lazy(() => import('@/views/ReferralProgramView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany],
		title: 'Programa de Referidos',
		icon: Star,
	},
	{
		path: '/companies/issuer',
		component: lazy(() => import('@/views/CompaniesIssuersView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany, userShould(Permissions.COMPANY_ADMIN)],
		title: 'Configuración de Facturador',
		icon: Settings,
	},
	{
		path: '/sign/:companyUid/:hash',
		component: lazy(() => import('@/views/MobileSignView')),
		layout: 'BlankLayout',
	},
	{
		path: '/plan',
		component: lazy(() => import('@/views/PlanView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany, userShould(Permissions.COMPANY_ADMIN)],
		title: 'Plan de Suscripción',
		icon: CreditCard,
	},
]

