import useAuthStore from '@/stores/auth-store'
import useCompaniesStore from '@/stores/companies-store'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import usePermissionsStore, { Permissions } from './permissions-store'
import { DocumentStatus } from '@/configs/constants'

const configs = {
	COMPANY_PENDING: {
		style: 'warning',
		message: () => {
			return 'Aún no has registrado tu Empresa'
		},
		ctaLink: () => `/companies`,
		ctaText: () => 'Registrar Empresa',
		dismissable: false,
	},
	INACTIVE_PLAN: {
		style: 'danger',
		message: () => {
			return (
				<>
					Tu plan se encuentra <b>inactivo</b>, por favor comunícate con soporte para reactivarlo.
				</>
			)
		},
		ctaLink: () => `/contacto`,
		ctaText: () => 'Contactar Soporte',
		dismissable: false,
	},
	ISSUER_PENDING: {
		style: 'warning',
		message: () => {
			return (
				<>
					Aún no has configurado el <b>Facturador de tu Empresa</b>
				</>
			)
		},
		ctaLink: ({ company }) => `/companies/issuer`,
		ctaText: () => 'Configurar Facturador',
		dismissable: false,
	},
	ISSUER_PROBLEM: {
		style: 'danger',
		message: () => {
			return 'Hay un problema con el Facturador de tu Empresa'
		},
		ctaLink: ({ company }) => `/companies/issuer`,
		ctaText: () => 'Verificar Facturador',
		dismissable: false,
	},
	ISSUER_CHECKING: {
		style: 'warning',
		message: () => {
			return 'Aun está en proceso tu solicitud de habilitación de Factura de Compra'
		},
		ctaLink: ({ company }) => `/companies/issuer`,
		ctaText: () => 'Verificar Facturador',
		dismissable: true,
	},
	CHECK_DOCUMENTS: {
		style: 'warning',
		message: ({ company }) => {
			let total = company.stats?.pending_documents
			return (
				<>
					Tienes documentos Pendientes de Emisión <b>({total})</b>
				</>
			)
		},
		ctaLink: () => `/documents?statusId=${DocumentStatus.PENDING}`,
		ctaText: () => 'Ver documentos',
		dismissable: true,
	},
	FREE_TRIAL_ENDING: {
		style: 'danger',
		message: ({ company }) => {
			let days = company.free_plan_left_days
			let text = days == 0 ? 'hoy' : days == 1 ? 'mañana' : `en ${days} días`
			return (
				<>
					Tu periodo de prueba <b>finaliza {text}</b>
				</>
			)
		},
		ctaLink: () => `/plan`,
		ctaText: () => 'Activar Plan',
		dismissable: true,
	},
}

const getDismissedKeys = () => {
	let dismissed = sessionStorage.getItem('dismissedNotif')
	if (dismissed) return JSON.parse(dismissed)
	return []
}

const setDismissedKeys = (keys) => {
	sessionStorage.setItem('dismissedNotif', JSON.stringify(keys))
}

export const useNotificationsStore = create(
	subscribeWithSelector((set, get) => ({
		current: null,
		setCurrent: (notif) => {
			if (notif && notif.dismissable && getDismissedKeys().includes(notif.fullkey)) set({ current: null })
			else set({ current: notif })
		},
		dismissNotification: (notif) => {
			if (!notif.dismissable) return
			if (notif.fullkey === get().current?.fullkey) {
				set({ current: null })
			}

			let dismissed = getDismissedKeys()
			if (dismissed.includes(notif.fullkey)) return
			dismissed.push(notif.fullkey)
			setDismissedKeys(dismissed)
		},
	}))
)

const updateCurrentNotification = () => {
	let notif = null
	const user = useAuthStore.getState().user

	if (user) {
		const company = useCompaniesStore.getState().activeCompany
		const userCan = usePermissionsStore.getState().userCan
		let key = null
		let data = null

		if (!company) {
			key = 'COMPANY_PENDING'
		} else if (!company.plan_active) {
			key = 'INACTIVE_PLAN'
		} else if (!company.issuer_id && userCan(Permissions.COMPANY_ADMIN)) {
			key = 'ISSUER_PENDING'
		} else if (company.issuer_purchase_status == 'invalid' && userCan(Permissions.COMPANY_ADMIN)) {
			key = 'ISSUER_PROBLEM'
		} else if (company.issuer_purchase_status == 'checking' && userCan(Permissions.COMPANY_ADMIN)) {
			key = 'ISSUER_CHECKING'
		} else if (company.free_plan_left_days !== null) {
			key = 'FREE_TRIAL_ENDING'
		} else if (company.stats?.pending_documents && userCan(Permissions.ISSUE_DOCUMENTS)) {
			key = 'CHECK_DOCUMENTS'
		}

		if (key) {
			const config = configs[key]
			const fullkey = [company?.uid, user.id, key].filter(Boolean).join('-')
			notif = {
				key,
				fullkey,
				style: config.style,
				message: config.message({ data, company }),
				ctaLink: config.ctaLink({ data, company }),
				ctaText: config.ctaText({ data, company }),
				dismissable: config.dismissable,
			}
		}
	}

	useNotificationsStore.getState().setCurrent(notif)
}

useCompaniesStore.subscribe((state) => state.activeCompany, updateCurrentNotification)
useAuthStore.subscribe((state) => state.user, updateCurrentNotification)

